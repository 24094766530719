export const wrapper = [
    // Mobile
    "mobile:p-5",
    "mobile:mt-4",
    // Tablet
    "tablet:p-0",
    "tablet:mt-14",
    // All
    "mt-14",
    "w-auto",
    "text-center",
];

export const certifications = ["text-lg", "font-light"];
export const copyright = ["text-xs", "font-thin", "mt-2"];
export const support = ["text-xs", "font-thin", "mt-6"]
