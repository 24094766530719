/** get url parameters */
/**
 * example
 * https://www.hearxgroup.com/heardigits?utm_source=Testing_UTM_Source&utm_medium=Testing_UTM_Medium&utm_campaign=Testing_UTM_Campaign */
/**
 * example
 * https://www.lexiehearing.com/?utm_source=campaign_source&utm_medium=campaign_medium&utm_campaign=campaign_name&utm_term=campaign_term&utm_content=campaign_content */

import { getCookie } from "./cookies";

// The default name for the UTM cookie params
const utmCampaignsCookieName = "utm_campaign_cookies";
// A set of searchable values when setting utm cookies
export const trackingVariables = [
  { name: "utm_source", leadType: "Lead_Source_UTM_Source__c" },
  { name: "utm_medium", leadType: "Lead_Source_UTM_Medium__c" },
  { name: "utm_campaign", leadType: "Lead_Source_UTM_Campaign__c" },
  { name: "utm_term", leadType: "Lead_Source_UTM_Term__c" },
  { name: "utm_content", leadType: "Lead_Source_UTM_Content__c" },
  { name: "msclkid", leadType: "Lead_Source_Bing_ClickID__c" },
  { name: "gclid", leadType: "Lead_Source_Google_ClickID__c" },
  { name: "sscid", leadType: "Lead_Source_ShareASale_ClickID__c" },
  { name: "tracking", leadType: "Lead_Source_ShareASale_ClickID__c" },
];

/*
 * Only call this function in a useEffect.
 */
export const setUTMCampaignCookies = () => {
  const cookiesAccepted = getCookie("CookieConsent").includes("marketing:true");
  if (!cookiesAccepted) return;
  const pageUrl = window.location.toString();
  if (pageUrl.includes("?")) {
    const parametersAsString = pageUrl.split("?");
    if (parametersAsString.length > 0) {
      console.log("%c Initialising UTM cookies", "color: #00a1ff");
      setParameterCookie(getURLParams(parametersAsString));
    }
  }
};

// Function that extracts all the UTM params and returns them in an object array
const getURLParams = (paramsAsString: string[]): {}[] => {
  const arrayOfURLParameters: {}[] = [];
  const URLPairs = paramsAsString[1].split("&");

  URLPairs.forEach((param, index) => {
    trackingVariables.filter((item) => {
      if (param.indexOf(item.name) >= 0) {
        arrayOfURLParameters.push({ type: item.leadType, value: param });
      }
    });
  });

  return arrayOfURLParameters;
};

// Function to set the UTM param cookies
const setParameterCookie = (params: {}[]) => {
  const siteCookies = getSiteCookies();
  const now = new Date();
  const expireDate = now.getDate() + 1814400000;
  /** 1814400000 = 3 weeks in milliseconds */
  now.setDate(expireDate);

  let updateCookies = false;

  siteCookies.forEach((cookie, index) => {
    cookie.split("=").filter((value) => {
      if (value === utmCampaignsCookieName) {
        /** check if document.cookies already has campaign cookies */
        updateCookies = true;
        console.log(
          "%c Replacing current UTM campaign cookies",
          "color:#ff9900"
        );
      }
    });
  });

  if (updateCookies) {
    /**
     * update the campaign utm cookies
     */
    updateCampaignCookies(now, params);
  } else {
    /**
     * add the utm campaign cookies
     */
    addCampaignCookies(now, params);
  }
};

// Function that adds the UTM cookie
const addCampaignCookies = (expireDate: Date, parameters: {}[]) => {
  if (document) {
    document.cookie =
      utmCampaignsCookieName +
      "=" +
      JSON.stringify(parameters) +
      "; " +
      "expires=" +
      expireDate.toUTCString() +
      "domain=.hearxgroup.com;path=/;";
  }
};

// Function that updates the UTM cookie
const updateCampaignCookies = (expireDate: Date, parameters: {}[]) => {
  if (document) {
    document.cookie =
      utmCampaignsCookieName +
      "=" +
      JSON.stringify(parameters) +
      "; " +
      "expires=" +
      expireDate.toUTCString() +
      "domain=.hearxgroup.com;path=/;";
  }
};

// Function to get all the sites cookies and add them to an array
const getSiteCookies = (): string[] => {
  const documentCookies: string[] = [];
  if (document) {
    document.cookie.split("; ").forEach(function (row) {
      documentCookies.push(row);
    });
  }
  return documentCookies;
};
