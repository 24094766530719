import { IFormFields, ISignUpFormState } from "../interfaces/ISignUpForm";
import axios from "axios";
import { getCookie } from "@/lib/cookies";
import { gTagEvent } from "@/lib/gtag";
import { captureException, withScope } from "@sentry/minimal";
// Prototype short functions
import "@/lib/prototypes";
import { trackingVariables } from "@/lib/utm-campaign";
// Form services to generate leads
const locationService = "https://freegeoip.app/json/";
const leadsService =
  "https://txx4xezyl5.execute-api.eu-west-1.amazonaws.com/prod/Leads";
const reCAPTCHASiteKey = "6Ld2RgwfAAAAANFaNOaKNHKRo4jfZOY212mmb0K3";
const utmCampaignsCookieName = "utm_campaign_cookies";

declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (key: string) => Promise<any>;
    };
  }
}

/*
Function that gets the clients location for default phone number
*/
export const getLocationInformation = async (): Promise<string> => {
  return axios.get(locationService).then((response) => {
    return response.data.country_code;
  });
};

/*
Function that handles the submission of forms
*/
export const submitForm = (
  formData: IFormFields,
  callback: (returnObject: {
    error: boolean;
    field?: string;
    responseData?: Record<string, any>;
  }) => void,
  optionalFields: null | string[] = [],
  urlParams?: any
) => {
  // Validating the form fields
  const formValidation = validateFormData(formData, optionalFields || []);

  // If there was an error return the data to the client
  if (formValidation.error) {
    callback(formValidation);
    return;
  }

  // Check if reCAPTCHA is a function
  if (!window.grecaptcha) {
    callback({
      error: true,
      field: "RE_CAPTCHA_NOT_FOUND",
    });
    return;
  }

  // Generate a reCAPTCHA verification token and send it through with the request
  window.grecaptcha.ready(() => {
    window.grecaptcha
      .execute(reCAPTCHASiteKey)
      .then((response) => {
        if (response) {
          // Send GA events
          sendGAEvents(formData);
          // Send the form data with the reCAPTCHA token
          sendRequest(
            getPostData(formData, response as string, urlParams)
          ).then((response) => {
            // Call the callback function
            callback(response);
          });
        } else {
          // Call the callback function
          callback({
            error: true,
            field: "RE_CAPTCHA_NULL",
          });
        }
      })
      .catch((err: string) => {
        console.warn(err);
        // Call the callback function
        callback({
          error: true,
          field: "RE_CAPTCHA",
        });
      });
  });
};

/*
Function that warms up reCAPTCHA for form request
*/
export const activateReCAPTCHA = async (
  callback: (active: boolean) => void
) => {
  if (!window.grecaptcha) {
    callback(false);
  }

  window.grecaptcha.ready(async () => {
    await window.grecaptcha
      .execute(reCAPTCHASiteKey)
      .then(function () {
        callback(true);
      })
      .catch(function (err) {
        // notify user, inside of console, as to why form failed.
        console.warn("window.grecaptcha activation error: ", err);
        callback(false);
      });
  });
};

/*
Function that validates the form inputs of the client
*/
const validateFormData = (
  formData: IFormFields,
  optionalFields: string[] = []
): { error: boolean; field: string } => {
  // Validate first name
  if (!optionalFields.includes("FIRST_NAME")) {
    if (formData.FIRST_NAME == "" || !formData.FIRST_NAME.isName()) {
      return {
        error: true,
        field: "FIRST_NAME",
      };
    }
  }

  // Validate last name
  if (!optionalFields.includes("LAST_NAME")) {
    if (formData.LAST_NAME == "" || !formData.LAST_NAME.isName()) {
      return {
        error: true,
        field: "LAST_NAME",
      };
    }
  }

  // Validating email address
  if (!optionalFields.includes("EMAIL")) {
    if (formData.EMAIL != "" && !formData.EMAIL.isEmail()) {
      return {
        error: true,
        field: "EMAIL",
      };
    }
  }

  // Validate phone number
  if (!optionalFields.includes("PHONE")) {
    if (!formData.PHONE?.isPhoneNumber()) {
      return {
        error: true,
        field: "PHONE",
      };
    }
    if (formData.PHONE?.length <= 0) {
      return {
        error: true,
        field: "PHONE",
      };
    }
  }

  return {
    error: false,
    field: "",
  };
};

/*
Function that posts the form data
*/
const sendRequest = async (
  postData: ISignUpFormState
): Promise<{
  error: boolean;
  field?: string;
  responseData?: Record<string, any>;
}> => {
  return axios
    .post(leadsService, postData)
    .then((response) => {
      return validateResponse(response);
    })
    .catch((err) => {
      console.warn(err);
      // Throwing error for Sentry to use
      withScope(function (scope) {
        scope.setTag(
          "form-error",
          postData.lead.CUSTOMFIELDS[0].FIELD_VALUE.toLowerCase() + " failed"
        );
        captureException(new Error(err));
      });
      return {
        error: true,
        field: "REQUEST_ERROR",
      };
    });
};

/*
Function that generates the post data for the form request
*/
const getPostData = (
  formData: IFormFields,
  reCAPTHCAToken: string,
  urlParams?: any
): ISignUpFormState => {
  return {
    recaptcha_response: reCAPTHCAToken,
    lead: setCustomFields(formData, urlParams),
  };
};

/*
Function that retrieves the UTM data and sets the custom fields
*/
const setCustomFields = (
  formData: IFormFields,
  urlParams?: any
): IFormFields => {
  const utmsFromCookie = getUtmsFromCookie();
  const utmsFromUrl = getUtmsFromUrlParams(urlParams || {});

  if (utmsFromUrl) {
    console.log("getting utms from url", utmsFromUrl);
    formData.CUSTOMFIELDS = [...formData.CUSTOMFIELDS, ...utmsFromUrl];
  } else if (utmsFromCookie) {
    console.log("getting utms from cookie", utmsFromCookie);
    formData.CUSTOMFIELDS = [...formData.CUSTOMFIELDS, ...utmsFromCookie];
  }

  return formData;
};

/*
FUnction to validate the response data you get from insightly
*/
const validateResponse = (responseData: { data: any } | []) => {
  if (Array.isArray(responseData)) {
    // Throwing error to be caught in Axios, SO that Sentry can capture it
    throw new Error("Invalid request");
  } else {
    if (responseData.data.errorMessage) {
      // Throwing error to be caught in Axios, SO that Sentry can capture it
      throw new Error(responseData.data.errorMessage);
    } else {
      return {
        error: false,
        responseData,
      };
    }
  }
};

/*
Function that sends the Google Analytics Events
*/
const sendGAEvents = (formData: IFormFields) => {
  // checking the ENV and fire GA events when it is production
  if (process.env.NODE_ENV === "production") {
    gTagEvent("complete-form", {
      event_category: formData.CUSTOMFIELDS[0].FIELD_VALUE,
      event_label: formData.CUSTOMFIELDS[1].FIELD_VALUE,
    });
    gTagEvent("conversion", {
      send_to: "AW-834511248/14tmCJzr3_IBEJDD9o0D",
      currency: "USD",
      transaction_id: "",
    });
  } else {
    console.log("complete-form", {
      event_category: formData.CUSTOMFIELDS[0].FIELD_VALUE,
      event_label: formData.CUSTOMFIELDS[1].FIELD_VALUE,
    });

    console.log("conversion", {
      send_to: "AW-834511248/14tmCJzr3_IBEJDD9o0D",
      currency: "USD",
      transaction_id: "",
    });
  }
};

/*
  Gets utms from cookie and maps it to custom fields
*/
export const getUtmsFromCookie = () => {
  let cookieValue;
  {
    const getCookie = (cookieName: string) => {
      let name = cookieName + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    };
    cookieValue = getCookie("utm_campaign_cookies");
  }

  if (cookieValue.length > 1) {
    if (cookieValue[0] === "=") {
      cookieValue = cookieValue.substring(1);
    }

    const cookieValueJSON = JSON.parse(cookieValue);

    return cookieValueJSON.map((_: unknown, index: number) => {
      const cookie = cookieValueJSON[index];
      const fieldValue = cookie.value.split("=");

      return {
        FIELD_NAME: cookie.type,
        FIELD_VALUE:
          fieldValue[1].replaceAll("+", " ").trim() || "Not Specified",
      };
    });
  }
};

/*
  Gets utms from url and maps it to custom fields
*/
export const getUtmsFromUrlParams = (urlParams: Record<string, string>) => {
  console.log("urlParams", urlParams);

  if (Object.keys(urlParams).length === 0) {
    return null;
  }

  return trackingVariables.map(({ name, leadType }) => {
    return {
      FIELD_NAME: leadType,
      FIELD_VALUE: urlParams[name] || "Not Specified",
    };
  });
};
