// Tailwind css
export const wrapper = [
    // Mobile
    "mobile:w-16",
    // Tablet & higher
    "tablet:w-1/12",
    "flex",
    "items-center",
    "justify-center",
    "h-full",
    "text-white",
    "bg-secondary",
	"z-20"
];

export const logo = [
    // Mobile
	"mobile:w-5",
    "mobile:h-7",
    // Tablet
    "tablet:w-7",
    "tablet:h-9",
];
