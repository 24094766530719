// Tailwind css
export const wrapper = [
  // Mobile
  "mobile:justify-between",
  "mobile:h-16",
  // Table & higher
  "tablet:justify-center",
  "tablet:h-16",
  // All
  "fixed",
  "top-0",
  "left-0",
  "flex",
  "items-center",
  "w-full",
  "bg-secondary",
  "z-[999]",
  "max-w-[100vw]",
];
