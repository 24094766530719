import React, { useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
// Functions / handlers
import { getUpdatedState, getAllParentNodes } from "@/lib/navigation";
// State management
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import {
  setNavigationItemState,
  getNavigationState,
  getMobileMenuIsOpen,
} from "@/redux/slices/navigation";
// Global component
import CustomLink from "@/components/custom-link";
import JsxParser from "react-jsx-parser";
// Tailwind classes
import {
  wrapper,
  firstLevelUl,
  secondLevelUl,
  lowerLevelUl,
  firstLevelLi,
  secondLevelLi,
  lowerLevelLi,
  firstLevelLiText,
  secondLevelLiText,
  lowerLevelLiText,
  description,
  firstAnchor,
  secondAnchor,
  lowerAnchor,
} from "./styles/navigation-items-classes";
// Interfaces
import { INavigationItem } from "@/components/navigation/interfaces/INavigationItem";

const NavigationItems: React.FC = () => {
  const ref = useRef<HTMLElement>(null);
  const dispatch = useAppDispatch();
  const mobileMenuIsOpen = useAppSelector(getMobileMenuIsOpen);
  // Getting the navigation state from the store
  const navigationState = [...useAppSelector(getNavigationState)];

  /**
   * Function that handles hover effects to set the navigation state to show sub items
   * @param parentState => from what part of the state the hovering item comes from
   * @param index => the index of the item within the parent state
   */
  const handleOnMouseEnter = (
    parentState: INavigationItem[],
    index: number
  ): void => {
    const navigationMockState = [
      ...getUpdatedState([...navigationState], parentState, index, true),
    ];
    dispatch(setNavigationItemState(navigationMockState));
  };

  /**
   * Function that handles hover effects to set the navigation state to hide sub items
   * @param parentState => from what part of the state the hovering item comes from
   * @param index => the index of the item within the parent state
   */
  const handleOnMouseLeave = (
    parentState: INavigationItem[],
    index: number
  ): void => {
    const navigationMockState = [
      ...getUpdatedState([...navigationState], parentState, index, false),
    ];
    dispatch(setNavigationItemState(navigationMockState));
  };

  /**
   * Function that handles mobile click effects to set the navigation state to show sub items
   * @param parentState => from what part of the state the hovering item comes from
   * @param index => the index of the item within the parent state
   */
  const handleOnClick = (
    parentState: INavigationItem[],
    index: number
  ): void => {
    const navigationMockState = [
      ...getUpdatedState([...navigationState], parentState, index, true),
    ];
    dispatch(setNavigationItemState(navigationMockState));
  };

  // Logic to hide and show the navigation items on mobile
  if (!mobileMenuIsOpen) {
    if (wrapper.indexOf("mobile:hidden") < 0) {
      wrapper.push(...["mobile:hidden"]);
      wrapper.splice(wrapper.indexOf("mobile:max-h-[calc(100%_-_64px)]"), 1);
      wrapper.splice(wrapper.indexOf("mobile:overflow-auto"), 1);
    }
  } else {
    if (wrapper.indexOf("mobile:hidden") > -1) {
      wrapper.splice(wrapper.indexOf("mobile:hidden"), 1);
      wrapper.push(
        ...["mobile:max-h-[calc(100%_-_64px)]", "mobile:overflow-auto"]
      );
    }
  }

  /**
   * A component that handles the navigation  state to display navigation items
   * @param navigationItems => The nav items that needs to be displayed
   * @param listLevel => The level of the navigation items
   * @param centerDropdown => Sets how the second level items will display
   * @param isLastSubMenu => Sets how the third level last item will display
   * @returns
   */
  const ListNavigationItems: React.FC<{
    navigationItems: INavigationItem[];
    listLevel: number;
    centerDropdown?: boolean;
    isLastSubMenu?: boolean;
  }> = ({ navigationItems, listLevel, centerDropdown, isLastSubMenu }) => {
    // Setting classes to specific sub levels
    const ulClass =
      listLevel === 1
        ? firstLevelUl
        : listLevel === 2
        ? secondLevelUl
        : lowerLevelUl;

    // Adding logic for large second level drop down
    if (centerDropdown && listLevel === 2) {
      if (ulClass.indexOf("tablet:left-0") > -1) {
        ulClass.splice(ulClass.indexOf("tablet:left-0"), 1);
        ulClass.push(
          "absolute",
          "left-0",
          "right-0",
          "flex",
          // "flex-wrap",
          "!justify-evenly"
        );
      }
    } else {
      if (ulClass.indexOf("tablet:left-1/2") > -1 && listLevel === 2) {
        ulClass.splice(ulClass.indexOf("tablet:left-1/2"), 1);
        ulClass.splice(ulClass.indexOf("tablet:transform"), 1);
        ulClass.splice(
          ulClass.indexOf("tablet:-translate-x-[calc(50%_-_223px)]"),
          1
        );
        ulClass.push(...["tablet:left-0"]);
      }
    }

    // Adding logic for 3ed level sub menus to not go past the right hand side
    if (isLastSubMenu && listLevel > 2) {
      if (ulClass.indexOf("tablet:left-0") > -1) {
        ulClass.splice(ulClass.indexOf("tablet:left-0"), 1);
        ulClass.push(...["tablet:right-0"]);
      }
    } else {
      if (ulClass.indexOf("tablet:right-0") > -1 && listLevel > 2) {
        ulClass.splice(ulClass.indexOf("tablet:right-0"), 1);
        ulClass.push(...["tablet:left-0"]);
      }
    }

    // Creating the navigation items
    const navItems = navigationItems.map(
      (item: INavigationItem, index: number) => {
        // Setting classes to specific sub levels
        let liClass =
          listLevel === 1
            ? [...firstLevelLi]
            : listLevel === 2
            ? [...secondLevelLi]
            : [...lowerLevelLi];
        let aClass =
          listLevel === 1
            ? [...firstAnchor]
            : listLevel === 2
            ? [...secondAnchor]
            : [...lowerAnchor];
        let textClass =
          listLevel === 1
            ? [...firstLevelLiText]
            : listLevel === 2
            ? [...secondLevelLiText]
            : [...lowerLevelLiText];

        // Adding hover effect / active effect
        if (item.hover) {
          if (liClass.indexOf("bg-secondary")) {
            liClass.splice(liClass.indexOf("bg-secondary"), 1);
            liClass.splice(liClass.indexOf("text-white"), 1);
          }
          liClass = [...liClass, "text-primary", "bg-[#49494a]"];
        }

        // Adding logic for large second level drop down
        if (item.subitems && item.subitems.length > 4) {
          if (liClass.indexOf("tablet:relative") > -1) {
            liClass.splice(liClass.indexOf("tablet:relative"), 1);
          }
        }

        // Adding logic for large second level drop down
        if (centerDropdown && listLevel === 2) {
          if (liClass.indexOf("tablet:justify-start") > -1) {
            liClass.splice(liClass.indexOf("tablet:justify-start"), 1);
            textClass.splice(textClass.indexOf("tablet:justify-start"), 1);
            liClass.splice(liClass.indexOf("laptop:w-44"), 1);
            liClass.push(
              ...["tablet:justify-center", "laptop:w-32", "desktop:w-40"]
            );
            textClass.push(
              ...["tablet:justify-center", "laptop:w-32", "desktop:w-40"]
            );
          }
        }

        // Adding logic if the nav item has a description
        if (item.description) {
          if (liClass.indexOf("tablet:h-14") > -1) {
            liClass.splice(liClass.indexOf("tablet:h-14"), 1);
          }
          liClass = [...liClass, "tablet:h-16"];
        }

        return (
          <li
            key={index}
            className={liClass.join(" ")}
            onClick={(e) => {
              if (isMobile && item.url === "#") {
                e.stopPropagation();
                handleOnClick(navigationItems, index);
              }
            }}
            onMouseEnter={() =>
              !isMobile && handleOnMouseEnter(navigationItems, index)
            }
            onMouseLeave={() =>
              !isMobile && handleOnMouseLeave(navigationItems, index)
            }
          >
            <CustomLink
              className={aClass.join(" ")}
              url={
                item.url.includes("http") ? item.url : item.url.toLowerCase()
              }
            >
              {item.description ? (
                <>
                  <p className={textClass.join(" ")}>
                    <JsxParser renderInWrapper={false} jsx={item.title} />
                  </p>
                  <p className={description.join(" ")}>{item.description}</p>
                </>
              ) : (
                <p className={textClass.join(" ")}>
                  <JsxParser renderInWrapper={false} jsx={item.title} />
                </p>
              )}
            </CustomLink>

            {item.hover && item.subitems && (
              <ListNavigationItems
                navigationItems={item.subitems}
                listLevel={listLevel + 1}
                centerDropdown={item.subitems.length > 4 && listLevel === 1}
                isLastSubMenu={
                  listLevel >= 2 && navigationItems.length - 1 === index
                }
              />
            )}
          </li>
        );
      }
    );
    return <ul className={ulClass.join(" ")}>{navItems}</ul>;
  };

  // Close all drop-downs when clicking outside the target element
  useEffect(() => {
    const checkIfClickedOutside = (e: TouchEvent | MouseEvent) => {
      const target = e.target as HTMLElement;

      if (ref.current && !getAllParentNodes(target).includes(ref.current)) {
        const navigationMockState = [
          ...getUpdatedState(
            [...navigationState],
            [...navigationState],
            0,
            false
          ),
        ];
        dispatch(setNavigationItemState(navigationMockState));
      }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, []);

  return (
    <nav className={wrapper.join(" ")} ref={ref}>
      <ListNavigationItems navigationItems={navigationState} listLevel={1} />
    </nav>
  );
};

export default NavigationItems;
