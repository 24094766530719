// Tailwind CSS
export const wrapper = ["flex", "w-full"];
export const container = ["relative", "w-auto", "text-secondary", "text-6xl", "font-raleway"];
export const backdrop = [
    // Mobile
    "mobile:h-3/5",
    "mobile:w-11/12",
    // mobile lg
    "mobile:h-3/5",
    "mobile:w-full",
    // Tablet
    "tablet:h-4/5",
    // All
    "absolute",
    "bg-accent",
    "opacity-80",
    "top-1/4",
    "left-8",
    "z-0",
    "transform",
    "translate-y-1",
];
export const text = ["relative", "z-10", "font-black"];
