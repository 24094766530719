// State management
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import { getMobileMenuIsOpen, toggleMobileNavigationMenu } from "@/redux/slices/navigation";
// Tailwind classes
import { wrapper, lines, text, closed, topLine, bottomLine } from "./styles/mobile-navigation-button-classes";

const MobileNavigationButton: React.FC = () => {
    const dispatch = useAppDispatch();
    const mobileMenuIsOpen = useAppSelector(getMobileMenuIsOpen);

    const handleClick = () => {
        dispatch(toggleMobileNavigationMenu(!mobileMenuIsOpen));
    };

    if (mobileMenuIsOpen) {
        if (wrapper.indexOf("mobile:order-1") < 0) wrapper.push(...["mobile:order-1"]);
    } else {
        if (wrapper.indexOf("mobile:order-1") > -1) wrapper.splice(wrapper.indexOf("mobile:order-1"), 1);
    }

    return (
        <div
            className={wrapper.join(" ")}
            onClick={(e) => {
                e.preventDefault();
                handleClick();
            }}
        >
            <div className={lines.join(" ")}>
                <span className={!mobileMenuIsOpen ? closed.join(" ") : topLine.join(" ")}></span>
                {!mobileMenuIsOpen && <span className={closed.join(" ")}></span>}
                <span className={!mobileMenuIsOpen ? closed.join(" ") : bottomLine.join(" ")}></span>
            </div>
            {!mobileMenuIsOpen && <p className={text.join(" ")}>Menu</p>}
        </div>
    );
};

export default MobileNavigationButton;
