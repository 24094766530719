import globalData from "@/i18n/json/global";
import CustomLink from "@/components/custom-link";
// Tailwind Classes
import { wrapper, social, socialItem, details } from "./styles/contact-classes";

const ContactDetails = () => {
    const contactDetails = globalData.data["contact-details"];
    const socialMedia: { [key: string]: string } = globalData.data["social-media"];

    return (
        <div className={wrapper.join(" ")}>
            <div className={social.join(" ")}>
                {Object.keys(socialMedia).map((item: string, index: number) => {
                    return (
                        <div key={index} className={socialItem.join(" ")}>
                            <CustomLink url={socialMedia[item]}>
                                <img src={`/assets/icons/social-media/${item.toLowerCase()}.svg`} alt={`${item} icon`} />
                            </CustomLink>
                        </div>
                    );
                })}
            </div>
            <ul className={details.join(" ")}>
                <li>
                    <CustomLink className="text-primary GA4-ContactUsCall" url={`mailto:${contactDetails.email}`}>
                        {contactDetails.email}
                    </CustomLink>
                </li>
                <li>US: <a href={`tel: ${contactDetails.telephone.us}`} className="GA4-ContactUsCall">{contactDetails.telephone.us}</a></li>
                <li>RSA: <a href={`tel: ${contactDetails.telephone.rsa}`} className="GA4-ContactUsCall">{contactDetails.telephone.rsa}</a></li>
            </ul>
        </div>
    );
};

export default ContactDetails;
