import { default as monday } from "./assets/Monday.svg";
import { default as tuesday } from "./assets/Tuesday.svg";
import { default as wednesday } from "./assets/Wednesday.svg";
import { default as thursday } from "./assets/Thursday.svg";
import { default as friday } from "./assets/Friday.svg";

import styles from "./promo-easter.module.scss";
import classNames from "classnames";
import { isBetweenTimes } from "util/isBetweenTimes";
import { useEffect, useState } from "react";

const GRAPHICS = [
  monday.src,
  tuesday.src,
  wednesday.src,
  thursday.src,
  friday.src,
];

const LINKS = [
  "https://docs.google.com/forms/d/e/1FAIpQLSct4pnGGfYo0zmSfa_93e_G6OZoCo4hjp0B_-2aDq7BVtDRsw/viewform?usp=pp_url",
  "https://docs.google.com/forms/d/e/1FAIpQLSedC1rmWpA5CE0yhxL_Cx61_ixC8a7yonLGfEu5nbTcmwMPsw/viewform?usp=pp_url",
  "https://docs.google.com/forms/d/e/1FAIpQLScVlxn8fyTh_6dncniLk4KXaRBi0h7bSVMBafG0WESvKFXWAg/viewform?usp=pp_url",
  "https://docs.google.com/forms/d/e/1FAIpQLSdwuGPvSexiFnf4b9I1IxYDGVjTY6wQSTudq52W3SBNKACuKw/viewform?usp=pp_url",
  "https://docs.google.com/forms/d/e/1FAIpQLSfU62bfzs0ABl_-N6l5ADxg0jHhdfLXFDMW1IxJ7gVsoao3KA/viewform?usp=pp_url",
];

export const PromoEaster = ({ day }: { day: number }) => {
  const [show, setShow] = useState(false);
  const todayIndex = getDay();
  useEffect(() => {
    if (day === todayIndex) setShow(true);
  }, []);
  if (show)
    return (
      <div className={classNames(styles.root, styles[`day-${day}`])}>
        <a href={LINKS[day - 1]} target="_blank" rel="noopener noreferrer">
          <img src={GRAPHICS[day - 1]} />
        </a>
      </div>
    );
  return null;
};

const APRIL_3 = 1680472800000;
const APRIL_4 = 1680559200000;
const APRIL_5 = 1680645600000;
const APRIL_6 = 1680732000000;
const APRIL_7 = 1680818400000;
const APRIL_8 = 1680904800000;

const getDay = () => {
  if (isBetweenTimes(APRIL_3, APRIL_4)) return 1;
  if (isBetweenTimes(APRIL_4, APRIL_5)) return 2;
  if (isBetweenTimes(APRIL_5, APRIL_6)) return 3;
  if (isBetweenTimes(APRIL_6, APRIL_7)) return 4;
  if (isBetweenTimes(APRIL_7, APRIL_8)) return 5;
  return 0;
};
