import { down, up, left, right, link } from "./styles/arrow-classes";
import { IArrow, IClassNames } from "./interfaces";

const Arrow: React.FC<IArrow> = ({ dir, color, size, scale,onClick }) => {
    // Creating class dictionary
    const classes: IClassNames = {
        down: [...down],
        up: [...up],
        left: [...left],
        right: [...right],
        link: [...link],
    };
    // Setting the main class
    const mainClass = [...classes[dir]];

    // Setting the size of the arrow
    if (size && size !== 1) {
        let borderTop = "border-t";
        let borderBottom = "border-b";
        let borderLeft = "border-l";
        let borderRight = "border-r";

        switch (size) {
            case 0:
                borderTop = "border-t-0";
                borderBottom = "border-b-0";
                borderLeft = "border-l-0";
                borderRight = "border-r-0";
                break;
            case 2:
                borderTop = "border-t-2";
                borderBottom = "border-b-2";
                borderLeft = "border-l-2";
                borderRight = "border-r-2";
                break;
        }

        if (mainClass.indexOf("border-b") !== -1) {
            mainClass.splice(mainClass.indexOf("border-b"), 1);
            mainClass.push(borderBottom);
        }

        if (mainClass.indexOf("border-t") !== -1) {
            mainClass.splice(mainClass.indexOf("border-t"), 1);
            mainClass.push(borderTop);
        }

        if (mainClass.indexOf("border-l") !== -1) {
            mainClass.splice(mainClass.indexOf("border-l"), 1);
            mainClass.push(borderLeft);
        }

        if (mainClass.indexOf("border-r") !== -1) {
            mainClass.splice(mainClass.indexOf("border-r"), 1);
            mainClass.push(borderRight);
        }
    }

    // Adding scaling to the arrows
    if (scale && mainClass.indexOf("transform") !== -1) {
        mainClass.push(...["transform", scale]);
    }

    // Changing the color of the arrows
    if (color && mainClass.indexOf("border-white") > -1) {
        mainClass.splice(mainClass.indexOf("border-white"), 1);
        mainClass.push(color);
    }

    return <div aria-label="Arrow component" className={mainClass.join(" ")} onClick={onClick}/>;
};

export default Arrow;
