import JsxParser from "react-jsx-parser"; // Package to parse string to JSX component
// Tailwind Classes
import { wrapper, container, backdrop, text } from "../content-heading/styles/content-heading-classes";
// Interfaces
import {IContentHeadingProps} from "@/components/content-heading/interfaces/IContentHeading";

const ContentHeading: React.FC<IContentHeadingProps> = ({ children, h, color, fontSize, fontWeight, backdropPosition, background, className }) => {
    const heading = `<${h}>${children}</${h}>`;
    const wrapperClasses = [...wrapper];
    const containClasses = [...container];
    const backdropClasses = [...backdrop];
    const textClasses = [...text];

    // Text color styles
    if (color) {
        if (containClasses.indexOf("text-secondary") > -1) {
            containClasses.splice(containClasses.indexOf("text-secondary"), 1);
            containClasses.push(color);
        }
    }

    if (fontWeight) {
        if (textClasses.indexOf("font-black") > -1) {
            textClasses.splice(textClasses.indexOf("font-black"), 1);
            textClasses.push(fontWeight);
        }
    }

    // Font Size
    if (fontSize) {
        if (containClasses.indexOf("text-6xl") > -1) {
            containClasses.splice(containClasses.indexOf("text-6xl"), 1);
            containClasses.push(fontSize);
        }
    }

    // Backdrop position when font size changes
    if (backdropPosition) {
        if (backdropClasses.indexOf("left-8") > -1) {
            backdropClasses.splice(backdropClasses.indexOf("left-8"), 1);
            backdropClasses.push(backdropPosition);
        }
    }

    // Backdrop background color
    if (background) {
        if (backdropClasses.indexOf("bg-accent") > -1) {
            backdropClasses.splice(backdropClasses.indexOf("bg-accent"), 1);
            backdropClasses.push(background);
        }
    }

    // Extra classes
    if (className) {
        wrapperClasses.push(...[className]);
    }

    return (
        <div className={wrapperClasses.join(" ")}>
            <div className={containClasses.join(" ")}>
                <JsxParser className={textClasses.join(" ")} jsx={heading} />
                <span className={backdropClasses.join(" ")}></span>
            </div>
        </div>
    );
};

export default ContentHeading;
