import { ICloseButton } from "./interfaces/ICloseButton";
import classNames from "classnames";
// Tailwind styles
import { wrapper, container, line1, line2 } from "./styles";

const CloseButton: React.FC<ICloseButton> = ({ color, closeHandler }) => {
    let line1Classes: string[] = [...line1];
    let line2Classes: string[] = [...line2];

    if (color) {
        if (line1Classes.indexOf("bg-secondary")) {
            line1Classes.splice(line1Classes.indexOf("bg-secondary"), 1);
            line1Classes.push(color);
        }
        if (line2Classes.indexOf("bg-secondary")) {
            line2Classes.splice(line2Classes.indexOf("bg-secondary"), 1);
            line2Classes.push(color);
        }
    }

    return (
        <button className={classNames(wrapper)} type="button" onClick={closeHandler}>
            <div className={classNames(container)}>
                <span className={classNames(line1Classes)}></span>
                <span className={classNames(line2Classes)}></span>
            </div>
        </button>
    );
};

export default CloseButton;
