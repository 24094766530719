import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { IFormFields } from "./interfaces/ISignUpForm";
import { submitForm, activateReCAPTCHA } from "./lib";
// Phone number selector
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
// FontAwesome icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductOfInterest from "./components/product-of-interest";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// Tailwind Classes
import {
  form,
  inputContainer,
  inputs,
  phoneInput,
  submitButton,
  inputClass,
  buttonClass,
  inputWrapper,
  errorMessage,
  textareaContainer,
  textarea,
  successMessage,
} from "./styles/get-in-touch";
import classNames from "classnames";
import { useRouter } from "next/router";

const GetInTouch: React.FC<{
  productOfInterest?: boolean;
  message?: boolean;
  formClassName?: string;
}> = ({ productOfInterest, message, formClassName = "" }) => {
  const [leadID, setLeadID] = useState<string>("");
  const { t } = useTranslation();

  // ReCAPTCHA Variables
  const [reCAPTCHAActive, setReCAPTCHAActive] = useState(false);
  const [reCAPTCHAError, setReCAPTCHAError] = useState("");
  // Form variables
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [formError, setFormError] = useState({
    error: false,
    field: "",
  } as { error: boolean; field?: string });
  const [thankYouState, setThankYouState] = useState({
    success: false,
    message: "",
  });
  const urlParams = useRouter().query;

  // Form state
  const [formState, setFormState] = useState<IFormFields>({
    FIRST_NAME: "",
    LAST_NAME: "",
    EMAIL: "",
    LEAD_SOURCE_ID: 3292000,
    LEAD_DESCRIPTION: "",
    PHONE: "",
    ORGANISATION_NAME: "",
    CUSTOMFIELDS: [
      {
        FIELD_NAME: "Lead_Source_Secondary__c",
        FIELD_VALUE: "Website Product Enquiry",
      },
      {
        FIELD_NAME: "hearX_product_multi__c",
        FIELD_VALUE: "Not Specified",
      },
      {
        FIELD_NAME: "Country_Code__c",
        FIELD_VALUE: "+1",
      },
    ],
  });

  /*
	Handler to submit the form data
	*/
  const handleSubmit = () => {
    // Setting the state of the form that it is busy processing the request
    setFormIsLoading(true);

    // Submit the request
    submitForm(
      formState,
      (response: {
        error: boolean;
        field?: string;
        responseData?: Record<string, any>;
      }) => {
        // Set the form error state
        setFormError(response);

        // Set the form back to ready to process
        setFormIsLoading(false);

        // Set the reCAPTCHA errors to nothing
        setReCAPTCHAError("");

        // Check if there are any errors in the response
        if (response.error) {
          // Set reCAPTCHA Errors for the user to see
          if (response.field) {
            switch (response.field) {
              case "RE_CAPTCHA_NOT_FOUND":
              case "RE_CAPTCHA_NULL":
                setReCAPTCHAError(t("forms.error-messages.re-captcha.empty"));
                break;
              case "RE_CAPTCHA":
                setReCAPTCHAError(t("forms.error-messages.re-captcha.invalid"));
                break;
            }
          }
          return;
        }

        response.responseData && setLeadID(response.responseData?.data.LEAD_ID);

        // If the form was requested successfully set this to success === true
        setThankYouState({
          success: true,
          message: t(`forms.success.get-in-touch`),
        });
      },
      null,
      urlParams
    );
  };

  /*
	Handler to update the form state when a field changes its value
	*/
  const onChangeHandler = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>
  ) => {
    const newFormState = { ...formState } as IFormFields;
    const fieldName = event.currentTarget.name;

    Object.keys(newFormState).forEach((key: string) => {
      let value: string = event.currentTarget.value;
      if (key === "LEAD_DESCRIPTION") {
        if (value.length > 250) {
          value = value.substring(0, 250);
        }
      }
      if (key === fieldName) {
        newFormState[key] = value;
      }
    });

    setFormState(newFormState);
  };

  /*
	Function that sets the product selected
	*/
  const onProductSelect = (product: string) => {
    const newFormState = { ...formState } as IFormFields;

    newFormState.CUSTOMFIELDS[1].FIELD_VALUE = product;

    setFormState(newFormState);
  };

  /*
	Function that handles the phone number change
	*/
  const onPhoneNumberChange = (
    number: string,
    countryData: {
      name: string;
      dialCode: string;
      countryCode: string;
      format: string;
    }
  ) => {
    const newFormState = { ...formState } as IFormFields;

    console.log({ countryData });

    newFormState.PHONE = `+${number}`;
    newFormState.CUSTOMFIELDS[2].FIELD_VALUE = `+${countryData.dialCode}`;

    setFormState(newFormState);
  };

  /*
	Handler to warm up reCAPTCHA
	*/
  const handleReCAPTCHAActivation = () => {
    activateReCAPTCHA((active) => {
      setReCAPTCHAActive(active);
    });
  };

  return (
    <div>
      <form
        className={classNames(form, formClassName)}
        onClick={() => {
          if (!reCAPTCHAActive) {
            handleReCAPTCHAActivation();
          }
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className={inputContainer}>
          <div className={inputWrapper}>
            <input
              className={inputs}
              name="FIRST_NAME"
              title={t("forms.get-in-touch.placeholders.first-name")}
              placeholder={t("forms.get-in-touch.placeholders.first-name")}
              onInput={(e) => {
                onChangeHandler(e);
              }}
              maxLength={26}
              value={formState.FIRST_NAME}
              required
            />
            {formError.error && formError.field === "FIRST_NAME" && (
              <p className={errorMessage}>
                {t("forms.error-messages.first-name.invalid")}
              </p>
            )}
          </div>
          <div className={inputWrapper}>
            <input
              className={inputs}
              name="LAST_NAME"
              title={t("forms.get-in-touch.placeholders.last-name")}
              placeholder={t("forms.get-in-touch.placeholders.last-name")}
              onInput={(e) => {
                onChangeHandler(e);
              }}
              maxLength={26}
              value={formState.LAST_NAME}
              required
            />
            {formError.error && formError.field === "LAST_NAME" && (
              <p className={errorMessage}>
                {t("forms.error-messages.last-name.invalid")}
              </p>
            )}
          </div>
          <div className={inputWrapper}>
            <input
              className={inputs}
              name="EMAIL"
              title={t("forms.get-in-touch.placeholders.email")}
              placeholder={t("forms.get-in-touch.placeholders.email")}
              onInput={(e) => {
                onChangeHandler(e);
              }}
              value={formState.EMAIL}
              required
            />
            {formError.error && formError.field === "EMAIL" && (
              <p className={errorMessage}>
                {t("forms.error-messages.email.invalid")}
              </p>
            )}
          </div>
          <div className={inputWrapper}>
            <input
              className={inputs}
              name="ORGANISATION_NAME"
              title={t("forms.get-in-touch.placeholders.organisation")}
              placeholder={t("forms.get-in-touch.placeholders.organisation")}
              onInput={(e) => {
                onChangeHandler(e);
              }}
              value={formState.ORGANISATION_NAME}
              required
            />
            {formError.error && formError.field === "ORGANISATION_NAME" && (
              <p className={errorMessage}>
                {t("forms.error-messages.organisation.invalid")}
              </p>
            )}
          </div>
          <div className={inputWrapper}>
            <div className={phoneInput}>
              <PhoneInput
                country={"us"}
                enableSearch={true}
                preferredCountries={["us", "za", "ca", "au", "gb"]}
                disableSearchIcon={true}
                inputClass={inputClass}
                buttonClass={buttonClass}
                placeholder={t("forms.get-in-touch.placeholders.phone")}
                onChange={onPhoneNumberChange}
                value={formState.PHONE}
                inputProps={{ required: true }}
                countryCodeEditable={false}
                masks={{ za: ".. ... ...." }}
              />
            </div>
            {/* When invalid phone number is detected, show invalid */}
            {formError.error && formError.field === "PHONE" && (
              <p className={errorMessage}>
                {t("forms.error-messages.phone.invalid")}
              </p>
            )}
          </div>
          {productOfInterest && (
            <div className={inputWrapper}>
              <ProductOfInterest onSelectHandler={onProductSelect} />
            </div>
          )}
        </div>
        {message && (
          <div className={textareaContainer}>
            <textarea
              name="LEAD_DESCRIPTION"
              className={textarea}
              placeholder={t("forms.get-in-touch.placeholders.message")}
              onInput={(e) => {
                onChangeHandler(e);
              }}
              maxLength={250}
              value={formState.LEAD_DESCRIPTION}
            />
            {formError.error && formError.field === "LEAD_DESCRIPTION" && (
              <p className={`${errorMessage} !-bottom-4 !left-4`}>
                {t("forms.error-messages.description.invalid")}
              </p>
            )}
          </div>
        )}
        {/* Check if the form request was success full and display the correct component */}
        {thankYouState.success && thankYouState.message.length > 0 ? (
          <>
            <p className={successMessage}>{thankYouState.message}</p>
            {leadID && (
              <img
                src={`https://www.shareasale.com/sale.cfm?tracking=${leadID}&amount=0.00&merchantID=142065&transtype=lead`}
                width="1"
                height="1"
              />
            )}
          </>
        ) : (
          <button className={submitButton()} type="submit">
            {formIsLoading ? (
              <FontAwesomeIcon icon={faSpinner as IconProp} spin={true} />
            ) : (
              t("forms.get-in-touch.button")
            )}
          </button>
        )}
        {/* ReCAPTCHA errors goes here */}
        {reCAPTCHAError !== "" && (
          <p className={errorMessage}>{reCAPTCHAError}</p>
        )}

        {/* Request errors goes here */}
        {formError.error && formError.field === "REQUEST_ERROR" && (
          <p className={errorMessage}>
            {t("forms.error-messages.request.invalid")}
          </p>
        )}
      </form>
    </div>
  );
};

export default GetInTouch;
