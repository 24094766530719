export const wrapper = [
    // Mobile
    "mobile:order-2",
    // Tablet
    "tablet:order-1",
    // All
    "bg-secondary",
    "w-auto",
];

export const ul = [
    // Mobile
    "mobile:gap-x-5",
    "mobile:pl-6",
    // Tablet
    "tablet:gap-x-20",
    "tablet:pl-0",
    // All
    "grid",
    "grid-cols-2",
    "gap-y-2",
];

export const li = [
    // All
    "font-light",
    "text-lg",
];
