import classNames from "classnames";

export const formDiv = classNames([
    "tablet:m-[4rem_4rem_0_4rem]",
    "w-full",
    "max-w-[460px]",
    "m-[2rem_4rem_0_4rem]",
    "border-2",
    "border-[#9299a0]",
    "rounded-lg",
    "p-[1.5rem]"
])

export const demoDiv = classNames([
    "w-full",
    "max-w-[460px]",
    "m-[1rem_4rem_0_4rem]",
    "tablet:m-[4rem_4rem_0_4rem]"
])

export const or = classNames([
    "tablet:mt-[6rem]",
    // All
    "mt-2",
    "font-xl",
    "font-medium",
    "text-center"
])