import {INavigationItem} from "@/components/navigation/interfaces/INavigationItem";
/**
 * Function that deep searches the state to set hovers to true and false
 * @param currentState => The current existing state of the navigation
 * @param parentState => Where the item originated from
 * @param itemIndex => The index of of the item in the origin / parent state
 * @param setValue => The value that needs to be set
 * @returns the updated state depending on either hover or blur
 */
export const getUpdatedState = (currentState: INavigationItem[], parentState: INavigationItem[], itemIndex: number, setValue: boolean): INavigationItem[] => {
    // Comparing the current state and parent state to check if the item is from the main items or sub items
    if (JSON.stringify(parentState) === JSON.stringify(currentState)) {
        currentState = [
            ...currentState.map((item: INavigationItem, index: number) => {
                return item.subitems
                    ? {
                          ...item,
                          hover: index === itemIndex ? setValue : false,
                          subitems: [
                              ...item.subitems.map((item) => {
                                  return { ...item, hover: false };
                              }),
                          ],
                      }
                    : { ...item, hover: index === itemIndex ? setValue : false };
            }),
        ];
    } else {
        currentState = [
            ...currentState.map((item, index) => {
                if (JSON.stringify(item.subitems) === JSON.stringify(currentState)) {
                    return item.subitems
                        ? {
                              ...item,
                              hover: index === itemIndex ? setValue : false,
                              subitems: [
                                  ...item.subitems.map((item) => {
                                      return { ...item, hover: false };
                                  }),
                              ],
                          }
                        : { ...item, hover: index === itemIndex ? setValue : false };
                } else {
                    return item.subitems ? { ...item, subitems: [...getUpdatedState([...item.subitems], parentState, itemIndex, setValue)] } : { ...item };
                }
            }),
        ];
    }

    return currentState;
};

/**
 * A function that allows us to check the target elements parents to hide the needed element when clicked away from the target aea
 * @param target => The element that has been clicked or  touched
 * @returns The array of parent elements
 */
export const getAllParentNodes = (target: HTMLElement) => {
    let element: HTMLElement | null = target;
    const elements = [];

    while (element) {
        elements.unshift(element);
        element = element.parentElement;
    }

    return elements;
};
