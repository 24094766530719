import Logo from "@/components/navigation/logo";
import NavigationItems from "@/components/navigation/navigation-items";
// import CurrencySelector from "@/components/billing/currency-selector";
import MobileNavigationButton from "./mobile-navigation-button";
// Tailwind classes
import { wrapper } from "./styles/navigation-classes";

const NavigationBar: React.FC = () => {
    return (
        <div className={wrapper.join(" ")}>
            <Logo />
            <NavigationItems />
            {/* <CurrencySelector /> */}
            <MobileNavigationButton />
        </div>
    );
};

export default NavigationBar;
