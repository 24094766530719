import CustomLink from "../custom-link";
// State management
import { useAppSelector } from "@/redux/hooks";
import { getMobileMenuIsOpen } from "@/redux/slices/navigation";
// Tailwind Classes
import { wrapper, logo } from "./styles/logo-classes";

/**
 * React functional Logo component that will be injected into the navigation component
 * @returns Returns a React Component
 */
const Logo: React.FC = () => {
    // Getting the locale that is active
    const mobileMenuIsOpen = useAppSelector(getMobileMenuIsOpen);

    if (mobileMenuIsOpen) {
        if (wrapper.indexOf("mobile:order-2") < 0) wrapper.push(...["mobile:order-2"]);
    } else {
        if (wrapper.indexOf("mobile:order-2") > -1) wrapper.splice(wrapper.indexOf("mobile:order-2"), 1);
    }

    return (
        <div className={wrapper.join(" ")}>
            <CustomLink url="/" ariaLabel="Go to home page">
                <img className={logo.join(" ")} width="16px" height="16px" src="/hearx-logo.svg" alt="hearX Group Logo" />
            </CustomLink>
        </div>
    );
};

export default Logo;
