import classNames from "classnames";
import styles from "./section-layout.module.scss";

const SectionLayout: React.FC<
  React.PropsWithChildren<{
    heading?: React.ReactNode;
    className?: string;
    subheading?: React.ReactNode;
    separatorProps?: {
      className?: string;
      variant?: "normal" | "offset";
    };
  }>
> = ({
  heading,
  children,
  className,
  subheading,
  separatorProps: { variant: separatorVariant = "normal" } = {},
}) => {
  const id = heading?.toString().toLowerCase().replace(/\s/g, "-");
  return (
    <section
      className={classNames(styles.root, className)}
      aria-labelledby={id}
    >
      {heading && (
        <>
          <div className={styles.header}>
            <h2
              id={id}
              className={classNames(
                styles.heading,
                styles[`separator-${separatorVariant}`]
              )}
            >
              {heading}
            </h2>
            <div className={classNames(styles.separator)} />
            {subheading && <p>{subheading}</p>}
          </div>
        </>
      )}
      {children}
    </section>
  );
};

export default SectionLayout;
