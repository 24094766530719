// TODO: When the whole site is Next js uncomment the below

// import Link from "next/link";
// import { useAppSelector } from "@/redux-config/hooks";
// import { getLocale } from "@/redux-config/slices/locale";
import { ICustomLink } from "./interfaces";

/**
 * A Higher order component for links, to make is safe to use
 * @param children => Is the elements that the higher order component needs to wrap
 * @param url => Is the link that the page needs to go to
 * @returns A reusable component for links
 */
const CustomLink: React.FC<ICustomLink> = ({ children, url, title, ariaLabel, className, external }) => {
    // const locale = useAppSelector(getLocale);
    // return url && url != "#" ? <Link href={`/${locale}/${url.replace(/\s/g, "-").toLowerCase()}`}><a>{children}</a></Link> : <>{children}</>;
    return url && url != "#" ? (
        <a
            className={className && className}
            title={title && title}
            aria-label={ariaLabel ? ariaLabel : "link"}
            href={`${url.replace(/\s/g, "-")}`}
            target={external ? "_blank" : "_self"}
        >
            {children}
        </a>
    ) : (
        <div className={className && className} title={title && title} aria-label={ariaLabel ? ariaLabel : "link"}>
            {children}
        </div>
    );
};

export default CustomLink;
