import { Trans, useTranslation } from "react-i18next";
import { useRouter } from "next/dist/client/router";
// Tailwind Classes
import {
  wrapper,
  certifications,
  copyright,
  support,
} from "./styles/footer-description-classes";

const FooterDescription = () => {
  const { t } = useTranslation();
  const route = useRouter().route;
  const thirdDescriptionRoutes = ["/pricing", "/who-we-are"];

  return (
    <div className={wrapper.join(" ")}>
      <div className={certifications.join(" ")}>
        {!thirdDescriptionRoutes.includes(route) ? (
          <>
            <p>{t("footer.certificates.first")}</p>
            <p>{t("footer.certificates.second")}</p>
          </>
        ) : (
          <p>{t("footer.certificates.third")}</p>
        )}
      </div>
      <p className={support.join(" ")}>
        <Trans i18nKey="footer.ethics">
          To report unethical behaviour, misconduct or harassment click
          <a
            className="text-primary"
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSejNjDBoeVvnzf17ykWGvJPhLmqa1x_NNorKhNdDG7h-jnO_A/viewform?usp=share_link"
          >
            here
          </a>
        </Trans>
      </p>
      <p className={support.join(" ")}>{t("footer.support")}</p>
      <p className={copyright.join(" ")}>{t("footer.copyright")}</p>
    </div>
  );
};

export default FooterDescription;
