declare global {
    interface Window {
        gtag: any;
        dataLayer: any;
    }
}
// Google tag manager reusable event function
export const gTagEvent = (action: string, props: {} = {}) => {
    if (typeof window !== "undefined") {
        gtag("event", action, props);
    }
};

function gtag(event: string, action: string, props: any) {
    if (typeof window !== "undefined") {
		window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
    }
}
