// Components
import NewsLetter from "./newsletter";
import SiteMap from "./site-map";
import ContactDetails from "./contact-details";
import FooterDescription from "./footer-description";
// Tailwind Classes
import { wrapper, content, contentBlocks } from "./styles/footer-classes";

const Footer: React.FC = () => {
    return (
        <div className={wrapper.join(" ")}>
            <NewsLetter />
            <div className={content.join(" ")}>
                <div className={contentBlocks.join(" ")}>
                    <SiteMap />
                    <ContactDetails />
                </div>
                <FooterDescription />
            </div>
        </div>
    );
};

export default Footer;
