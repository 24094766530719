import classNames from "classnames";

export const wrapper = classNames(
    // Mobile
    "mobile:p-5",
    "mobile:pb-10",
    // Tablet
    "tablet:p-10",
    // All
    "relative",
    "bg-gray-100"
);

export const heading = (theme = 'text-primary') => classNames(
    "font-bold",
    "text-2xl",
    "text-center",
    theme
)

export const subheading = classNames(
    // Mobile
    "mobile:text-base",
    // Tablet
    "tablet:text-lg",
    // All
    "mt-5",
    "mb-5",
    "text-center"
);

export const description = classNames(
    // Mobile
    "mobile:text-base",
    // Tablet
    "tablet:text-lg",
    // All
    "mt-5",
    "text-center",
    "font-medium"
);

export const FormButton = (theme?: string) => classNames(
    "text-xl",
    "bg-primary",
    "p-[10px_16px]",
    "rounded-lg",
    "m-auto",
    "text-white",
    theme ?? ""
)

export const link = (color?: string) => classNames("font-bold", "z-40", color ?? "text-primary");

export const disclaimer = classNames(
    // Mobile
    "mobile:text-center",
    "mobile:pl-0",
    // Tablet
    "tablet:text-left",
    "tablet:pl-6",
    // All
    "absolute",
    "w-full",
    "left-0",
    "bottom-1",
    "text-gray-400",
    "text-xs"
);
