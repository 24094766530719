import classNames from "classnames";

// Normal form
export const from = classNames(
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "w-full"
);
export const inputWrapper = classNames(
    // All
    "relative",
    "w-full",
    "flex",
    "flex-col",
    "items-start",
    "justify-center",
    "h-8",
    "mb-4"
);

export const inputErrorMessage = classNames(
    "absolute",
    "-bottom-4",
    "left-0",
    "text-red-300",
    "text-xs"
);
export const input = classNames(
    "w-full",
    "h-full",
    "pl-2",
    "text-secondary",
    "rounded",
    "focus:outline-none",
    "font-light"
);
export const button = classNames(
    "w-full",
    "bg-accent",
    "text-white",
    "text-center",
    "rounded",
    "h-8"
);
export const disclaimer = classNames("w-full", "mt-2");

// Popup classes
export const popupInputWrapper = classNames(
    "relative",
    "w-full",
    "flex",
    "flex-col",
    "items-start",
    "justify-center",
    "h-8",
    "mb-8"
);
export const popupErrorMessage = classNames(
    "absolute",
    "-bottom-6",
    "left-2",
    "text-red-300",
    "text-xs"
);
export const popupWrapper = classNames(
    // All
    "flex",
    "items-center",
    "justify-center",
    "fixed",
    "top-0",
    "left-0",
    "w-full",
    "h-full",
    "p-2",
    "z-50",
);
export const closeButton = classNames(
    // Mobile
    "mobile:right-3",
    "mobile:top-2",
    // Tablet
    "tablet:right-2",
    "tablet:top-0",
    // All
    "absolute",
);

export const popupBackDrop = classNames(
    "fixed",
    "top-0",
    "left-0",
    "w-full",
    "h-full",
    "bg-secondary",
    "bg-opacity-80"
);
export const popupFormWrapper = classNames(
    // Mobile
    "mobile:w-full",
    "mobile:m-2",
    // Tablet
    "tablet:text-lg",
    "tablet:w-full",
    "tablet:max-w-md",
    "tablet:rounded-3xl",
    // All
    "relative",
    "z-10",
    "max-w-md",
    "bg-white",
    "p-6",
    "rounded-3xl",
    "max-h-full",
    "overflow-auto"
);

export const popupSubHeading = classNames(
    // Mobile
    "mobile:text-base",
    // Tablet
    "tablet:text-lg",
    // All
    "text-center",
    "pl-6",
    "pr-6",
    "mb-6"
);
export const popupInputs = classNames(
    "w-full",
    "pt-2",
    "pb-2",
    "pl-4",
    "pr-4",
    "rounded-xl",
    "bg-gray-200",
    "placeholder-secondary"
);
export const popupFormButton = classNames(
    "w-60",
    "h-12",
    "m-2",
    "bg-primary",
    "text-white",
    "text-lg",
    "rounded-xl"
);
export const popupDisclaimer = classNames(
    // Mobile
    "mobile:text-base",
    //All
    "w-11/12",
    "m-auto",
    "mt-4"
);

export const popupInputErrorMessage = classNames(
    "absolute",
    "bottom-0",
    "left-3",
    "text-red-400"
);
export const successMessage = classNames("text-center", "text-base", "pt-2");
export const errorMessage = classNames(
    "text-center",
    "text-base",
    "pt-2",
    "text-red-400"
);
