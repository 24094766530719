export const wrapper = [
    // Mobile
    "mobile:w-full",
    // Tablet && higher
    "tablet:w-1/3",
    // Desktop & higher
    "desktop:w-1/5",
    // ALl
    "flex",
    "items-start",
    "justify-center",
];

export const container = [
    // All
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "bg-secondary",
    "w-80",
];
