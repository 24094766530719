declare global {
    interface String {
        // Form Input value checks
        isName: () => boolean;
        isPhoneNumber: () => boolean;
        isEmail: () => boolean;
        isDescription: () => boolean;
    }
}

// STRINGS
// An instance of the String object to validate names
String.prototype.isName = function () {
    const regex = /^[A-Za-z0-9 ]+$/
    const name = this.toString();
    return regex.test(name);
};
// An instance of the String object to validate a phone number
String.prototype.isPhoneNumber = function () {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const phoneNumber = this.toString();
    return regex.test(phoneNumber);
};
// An instance of the String object to validate a email address
String.prototype.isEmail = function () {
    const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;;
    const email: string = this.toString();
    return regex.test(email);
};
// An instance of the String object to validate descriptions
String.prototype.isDescription = function () {
    return this.toString().length > 6;
};

export default null;
