// Tailwind css
export const wrapper = [
  // Mobile
  "mobile:fixed",
  "mobile:top-0",
  "mobile:left-0",
  "mobile:w-full",
  "mobile:h-full",
  "mobile:pb-10",
  "mobile:max-h-[calc(100%_-_64px)]",
  "mobile:mt-[64px]",
  "mobile:overflow-auto",
  "mobile:z-10",
  // Laptop & higher
  "tablet:block",
  "tablet:relative",
  "tablet:w-4/5",
  // "tablet:w-full",
  "tablet:h-full",
  "tablet:pb-0",
  "tablet:mt-0",
  // All
  "bg-secondary",
];

export const firstLevelUl = [
  // Mobile
  "mobile:flex-col",
  "mobile:pt-24",
  // Laptop & higher
  "tablet:flex-row",
  "tablet:items-center",
  "tablet:justify-center",
  "tablet:pt-0",
  // All
  "flex",
  "w-full",
  "h-full",
  "m-auto",
  "bg-secondary",
  "relative",
];

export const secondLevelUl = [
  // Mobile
  "mobile:relative",
  "mobile:flex-col",
  "mobile:w-full",
  "mobile:-top-px",
  // Laptop & higher
  "tablet:absolute",
  "tablet:top-full",
  "tablet:w-min",
  "tablet:left-0",
  // "tablet:right-4",
  "tablet:flex-row",
  "tablet:items-center",
  "tablet:justify-start",
  // All
  "flex",
  "shadow-navigation",
  "z-10",
  "bg-secondary",
];

export const lowerLevelUl = [
  // Mobile
  "mobile:shadow-none",
  "mobile:w-full",
  // Laptop & higher
  "tablet:absolute",
  "tablet:top-full",
  "tablet:left-0",
  "tablet:items-center",
  "tablet:justify-start",
  "tablet:w-min",
  "tablet:shadow-navigation",
  // All
  "flex",
  "flex-col",
  "z-10",
  "bg-secondary",
];

export const firstLevelLi = [
  // Mobile
  "mobile:flex-col",
  "mobile:w-full",
  "mobile:h-auto",
  "mobile:items-center",
  "mobile:justify-start",
  // Laptop & higher
  "tablet:relative",
  "tablet:flex-row",
  "tablet:items-center",
  "tablet:justify-center",
  "tablet:w-1/5",
  "tablet:h-full",
  "tablet:text-lg",
  // All
  "flex",
  "w-min",
  "h-full",
  "bg-secondary",
  "font-light",
  "text-white",
  "whitespace-nowrap",
  "cursor-pointer",
];

export const firstAnchor = ["tablet:w-full", "tablet:h-full"];

export const firstLevelLiText = [
  // Mobile
  "mobile:h-16",
  "mobile:text-left",
  "mobile:border-b",
  "mobile:border-gray-400",
  "mobile:w-72",
  "mobile:justify-start",
  // Tablet & higher
  "tablet:h-full",
  "tablet:w-full",
  "tablet:border-none",
  "tablet:justify-center",
  // All
  "flex",
  "items-center",
  "block",
];

export const secondLevelLi = [
  // Mobile
  "mobile:flex-col",
  "mobile:w-full",
  "mobile:h-auto",
  "mobile:items-center",
  "mobile:justify-start",
  // Laptop & higher
  "tablet:items-center",
  "tablet:justify-start",
  "tablet:w-min",
  "tablet:h-14",
  "tablet:px-4",
  "tablet:text-sm",
  // Laptop
  "laptop:w-44",
  // All
  "relative",
  "flex",
  "text-white",
  "bg-secondary",
  "whitespace-nowrap",
  "cursor-pointer",
];

export const secondAnchor = [
  // All
  "tablet:w-min",
  "tablet:h-full",
];

export const secondLevelLiText = [
  // Mobile
  "mobile:h-16",
  "mobile:w-72",
  // Tablet & higher
  "tablet:h-14",
  "tablet:w-full",
  "tablet:text-xs",
  // All
  "flex",
  "items-center",
  "justify-start",
  "block",
  "m-auto",
];

export const lowerLevelLi = [
  // Mobile
  "mobile:flex",
  "mobile:items-center",
  "mobile:justify-center",
  // Tablet
  "tablet:items-start",
  "tablet:justify-center",
  "tablet:w-64",
  "tablet:pl-8",
  "tablet:pr-4",
  // All
  "flex",
  "flex-col",
  "h-14",
  "text-white",
  "bg-secondary",
  "whitespace-nowrap",
  "cursor-pointer",
];

export const lowerAnchor = [
  // Mobile
  "mobile:items-center",
  "mobile:justify-center",
  // Laptop & higher
  "tablet:items-start",
  "tablet:justify-center",
  "tablet:w-full",
  "tablet:h-full",
  // All
  "flex",
  "flex-col",
];

export const lowerLevelLiText = [
  // All
  "mobile:w-60",
  "mobile:text-base",
  // Tablet & higher
  "tablet:w-full",
  // All
  "block",
  "font-bold",
];

export const description = [
  // Mobile
  "mobile:w-60",
  "mobile:text-sm",
  // Tablet & higher
  "tablet:w-full",
  // All
  "font-thin",
];
