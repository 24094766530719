export const wrapper = [
    // Mobile
	"mobile:order-1",
	"mobile:pb-8",
	// Tablet
	"tablet:order-2",
	"tablet:pb-0",
	// All
    "bg-secondary",
    "w-auto",
];

export const social = [
    // All
    "flex",
    "items-end",
    "justify-start",
];

export const socialItem = ["mr-4"];
export const details = ["mt-2", "font-light", "grid", "grid-cols-1", "gap-y-2"];
