import {useTranslation} from "react-i18next";
import footerData from "@/i18n/json/footer";
import CustomLink from "@/components/custom-link";
// Tailwind Classes
import {wrapper, ul, li} from "./styles/sitemap-classes";
// Interfaces
import {ISitemapItem} from "@/components/footer/interfaces/ISitemapItem";

const SiteMap = () => {
    const {t} = useTranslation();
    const steMap: ISitemapItem = footerData["en"].sitemap; // Using default language i18n footer file
    return (
        <div className={wrapper.join(" ")}>
            <ul className={ul.join(" ")}>
                {Object.keys(steMap).map((item: string, index: number) => {
                    return (
                        <li key={index} className={li.join(" ")}>
                            <CustomLink url={steMap[item].url}>
                                {t(`footer.sitemap.${item}.title`)}
                            </CustomLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SiteMap;
