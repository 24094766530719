import { SignUpForm } from "@/components/forms";
import { useTranslation } from "react-i18next";
// Tailwind Classes
import { wrapper, container } from "./styles/newsletter-classes";

const NewsLetter = () => {
  const { t } = useTranslation();
  return (
    <div className={wrapper.join(" ")}>
      <div className={container.join(" ")}>
        <SignUpForm
          formId="newsletter-signup-footer"
          product="Not Specified"
          lead="Newsletter"
          type="newsletter"
          isPopup={false}
        />
      </div>
    </div>
  );
};

export default NewsLetter;
