import scss from "@/styles/components/arrow.module.scss";

export const wrapper = [];
const square = [
    // All
    "relative",
    "w-3",
    "h-3",
    "border-white",
    "transform",
    "rotate-45",
    "z-0",
];

export const down = [
    ...square,
    // All
    "border-r",
    "border-b",
    "-top-1",
];

export const up = [
    ...square,
    // All
    "border-t",
    "border-l",
];

export const left = [
    ...square,
    // All
    "border-t",
    "border-r",
];

export const right = [
    ...square,
    // All
    "border-b",
    "border-l",
];

export const link = [
    scss["link"],
    // All
    "relative",
    "w-2.5",
    "h-2.5",
    "border-white",
    "z-0",
    "border-t",
    "border-r",
];
