import React, { useState, FormEvent, useEffect, ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { IFormFields } from "./interfaces/ISignUpForm";
import { submitForm, activateReCAPTCHA } from "./lib";
import { PopupModal } from "react-calendly";
// Phone number selector
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
// FontAwesome icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductOfInterest from "./components/product-of-interest";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import wantMoreInfoStyles from "./styles/want-more-info.module.scss";
// Tailwind Classes
import {
  form,
  inputContainer,
  inputs,
  phoneInput,
  inputClass,
  buttonClass,
  inputWrapper,
  errorMessage,
  textareaContainer,
  textarea,
  successMessage,
  submitButton,
} from "./styles/get-in-touch";
import {
  description,
  FormButton,
  heading,
  link,
  subheading,
} from "@/components/home/get-in-touch/styles";
import { demoDiv, formDiv, or } from "./styles/request-demo";
import PrivacyText from "@/components/privacy-text";
import classNames from "classnames";
import { PromoEaster } from "../promo-easter/promo-easter";
import { useRouter } from "next/router";

const RequestDemoForm: React.FC<{
  productOfInterest?: boolean;
  message?: boolean;
  themeColor?: { color?: string; backgroundColor?: string };
  translationPrefix?: string;
  bookButtonOnly?: boolean;
  bookButtonText?: React.ReactNode;
  bookButtonClassNames?: string;
  showPromo?: boolean;
  formId: string;
  formClassName?: string;
  bookADemoPanel?: ReactNode;
  formFooter?: ReactNode;
  className?: string;
  formDivClassName?: string;
  leadSourceSecondary?: string;
  leadSourceUTMCampaign?: string;
  hearXProductMulti?: string;
}> = ({
  productOfInterest,
  message,
  themeColor = {
    color: "text-primary",
    backgroundColor: "bg-primary",
  },
  translationPrefix = "",
  bookButtonOnly,
  bookButtonText,
  bookButtonClassNames = "",
  showPromo,
  formId,
  formClassName = "",
  bookADemoPanel,
  formFooter,
  className,
  formDivClassName,
  leadSourceSecondary,
  leadSourceUTMCampaign,
  hearXProductMulti,
}) => {
  const [leadID, setLeadID] = useState("");
  const productColor = extractHexValue(themeColor?.color);
  const calendlyUrl = `https://calendly.com/d/2cs-rrm-978/hearx-group-product-demo?hide_gdpr_banner=1&primary_color=${productColor}`;
  const translationPrefixWithDot = translationPrefix
    ? translationPrefix + "."
    : "";
  const { t } = useTranslation();
  // ReCAPTCHA Variables
  const [reCAPTCHAActive, setReCAPTCHAActive] = useState(false);
  const [reCAPTCHAError, setReCAPTCHAError] = useState("");
  // Form variables
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [formError, setFormError] = useState({
    error: false,
    field: "",
  } as { error: boolean; field?: string });
  const [thankYouState, setThankYouState] = useState({
    success: false,
    message: "",
  });

  const [popupState, setPopupState] = useState<{
    open: boolean;
    element: HTMLElement | null;
  }>({
    open: false,
    element: null,
  });
  // Form state
  const [formState, setFormState] = useState<IFormFields>({
    FIRST_NAME: "",
    LAST_NAME: "",
    EMAIL: "",
    LEAD_SOURCE_ID: 3292000,
    LEAD_DESCRIPTION: "",
    PHONE: "",
    ORGANISATION_NAME: "",
    CUSTOMFIELDS: removeUndefinedFromArray([
      {
        FIELD_NAME: "Lead_Source_Secondary__c",
        FIELD_VALUE: leadSourceSecondary || "Website Product Enquiry",
      },
      ...(leadSourceUTMCampaign
        ? [
            {
              FIELD_NAME: "Lead_Source_UTM_Campaign__c",
              FIELD_VALUE: leadSourceUTMCampaign || "",
            },
          ]
        : []),
      {
        FIELD_NAME: "hearX_product_multi__c",
        FIELD_VALUE: hearXProductMulti || "Not Specified",
      },
      {
        FIELD_NAME: "Country_Code__c",
        FIELD_VALUE: "+1",
      },
    ]),
  });
  console.log({ formState });
  const urlParams = useRouter().query;

  useEffect(() => {
    if (document) {
      setPopupState({
        ...popupState,
        element: document.getElementById("__next"),
      });
    }
  }, []);

  /*
	Handler to submit the form data
	*/
  const handleSubmit = () => {
    // Setting the state of the form that it is busy processing the request
    setFormIsLoading(true);

    // Submit the request
    submitForm(
      formState,
      (response: {
        error: boolean;
        field?: string;
        responseData?: Record<string, any>;
      }) => {
        // Set the form error state
        setFormError(response);

        // Set the form back to ready to process
        setFormIsLoading(false);

        // Set the reCAPTCHA errors to nothing
        setReCAPTCHAError("");

        // Check if there are any errors in the response
        if (response.error) {
          // Set reCAPTCHA Errors for the user to see
          if (response.field) {
            switch (response.field) {
              case "RE_CAPTCHA_NOT_FOUND":
              case "RE_CAPTCHA_NULL":
                setReCAPTCHAError(
                  t(
                    translationPrefixWithDot +
                      "forms.error-messages.re-captcha.empty"
                  )
                );
                break;
              case "RE_CAPTCHA":
                setReCAPTCHAError(
                  t(
                    translationPrefixWithDot +
                      "forms.error-messages.re-captcha.invalid"
                  )
                );
                break;
            }
          }
          return;
        }

        response.responseData && setLeadID(response.responseData?.data.LEAD_ID);
        // If the form was requested successfully set this to success === true
        setThankYouState({
          success: true,
          message: t(`forms.success.get-in-touch`),
        });
      },
      null,
      urlParams
    );
  };

  /*
	Handler to update the form state when a field changes its value
	*/
  const onChangeHandler = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>
  ) => {
    const newFormState = { ...formState } as IFormFields;
    const fieldName = event.currentTarget.name;

    Object.keys(newFormState).forEach((key: string) => {
      let value: string = event.currentTarget.value;
      if (key === "LEAD_DESCRIPTION") {
        if (value.length > 250) {
          value = value.substring(0, 250);
        }
      }
      if (key === fieldName) {
        newFormState[key] = value;
      }
    });

    setFormState(newFormState);
  };

  /*
	Function that sets the product selected
	*/
  const onProductSelect = (product: string) => {
    const newFormState = { ...formState } as IFormFields;

    newFormState.CUSTOMFIELDS[1].FIELD_VALUE = product;

    setFormState(newFormState);
  };

  /*
	Function that handles the phone number change
	*/
  const onPhoneNumberChange = (
    number: string,
    countryData: {
      name: string;
      dialCode: string;
      countryCode: string;
      format: string;
    }
  ) => {
    const newFormState = { ...formState } as IFormFields;

    newFormState.PHONE = `+${number}`;
    const countryCodeField = newFormState.CUSTOMFIELDS.find(
      (field) => field.FIELD_NAME === "Country_Code__c"
    );
    if (countryCodeField) {
      countryCodeField.FIELD_VALUE = `+${countryData.dialCode}`;
    }

    setFormState(newFormState);
  };

  /*
	Handler to warm up reCAPTCHA
	*/
  const handleReCAPTCHAActivation = () => {
    activateReCAPTCHA((active) => {
      setReCAPTCHAActive(active);
    });
  };

  // TODO: Add the translation text

  if (bookButtonOnly)
    return (
      <>
        <button
          type={"button"}
          className={classNames(
            bookButtonClassNames,
            FormButton(themeColor?.backgroundColor ?? ""),
            "GA4-BookADemo"
          )}
          onClick={() => {
            setPopupState({ ...popupState, open: true });
          }}
        >
          {bookButtonText ||
            t(translationPrefixWithDot + "forms.get-in-touch.book-demo.button")}
        </button>

        <PopupModal
          url={calendlyUrl}
          open={popupState.open}
          onModalClose={() => {
            setPopupState({ ...popupState, open: false });
          }}
          rootElement={popupState.element as HTMLElement}
        />
      </>
    );

  return (
    <>
      <div
        className={classNames(
          "flex flex-col items-center tablet:flex-row tablet:items-stretch tablet:justify-center",
          className
        )}
      >
        <div className={classNames(formDiv, formDivClassName, "relative")}>
          {showPromo && <PromoEaster day={1} />}
          <h3 className={heading(themeColor?.color)}>
            <Trans default="LET US CONTACT YOU" components={{ br: <br /> }}>
              {translationPrefixWithDot}forms.get-in-touch.form-heading
            </Trans>
          </h3>
          <p className={subheading}>
            <Trans components={{ br: <br /> }}>
              {translationPrefixWithDot}forms.get-in-touch.subheading
            </Trans>
          </p>
          <form
            id={formId}
            className={classNames(form, formClassName)}
            onClick={() => {
              if (!reCAPTCHAActive) {
                handleReCAPTCHAActivation();
              }
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className={"mobile:grid-cols-1 tablet:grid-cols-1 grid"}>
              <div className={inputWrapper}>
                <input
                  className={inputs}
                  name="FIRST_NAME"
                  title={t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.placeholders.first-name"
                  )}
                  placeholder={t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.placeholders.first-name"
                  )}
                  onInput={(e) => {
                    onChangeHandler(e);
                  }}
                  maxLength={26}
                  value={formState.FIRST_NAME}
                  required
                />
                {formError.error && formError.field === "FIRST_NAME" && (
                  <p className={errorMessage}>
                    {t(
                      translationPrefixWithDot +
                        "forms.error-messages.first-name.invalid"
                    )}
                  </p>
                )}
              </div>
              <div className={inputWrapper}>
                <input
                  className={inputs}
                  name="LAST_NAME"
                  title={t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.placeholders.last-name"
                  )}
                  placeholder={t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.placeholders.last-name"
                  )}
                  onInput={(e) => {
                    onChangeHandler(e);
                  }}
                  maxLength={26}
                  value={formState.LAST_NAME}
                  required
                />
                {formError.error && formError.field === "LAST_NAME" && (
                  <p className={errorMessage}>
                    {t(
                      translationPrefixWithDot +
                        "forms.error-messages.last-name.invalid"
                    )}
                  </p>
                )}
              </div>
              <div className={inputWrapper}>
                <input
                  className={inputs}
                  name="EMAIL"
                  title={t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.placeholders.email"
                  )}
                  placeholder={t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.placeholders.email"
                  )}
                  onInput={(e) => {
                    onChangeHandler(e);
                  }}
                  value={formState.EMAIL}
                  required
                />
                {formError.error && formError.field === "EMAIL" && (
                  <p className={errorMessage}>
                    {t(
                      translationPrefixWithDot +
                        "forms.error-messages.email.invalid"
                    )}
                  </p>
                )}
              </div>
              <div className={inputWrapper}>
                <input
                  className={inputs}
                  name="ORGANISATION_NAME"
                  title={t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.placeholders.organisation"
                  )}
                  placeholder={t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.placeholders.organisation"
                  )}
                  onInput={(e) => {
                    onChangeHandler(e);
                  }}
                  value={formState.ORGANISATION_NAME}
                  required
                />
                {formError.error && formError.field === "ORGANISATION_NAME" && (
                  <p className={errorMessage}>
                    {t(
                      translationPrefixWithDot +
                        "forms.error-messages.organisation.invalid"
                    )}
                  </p>
                )}
              </div>
              <div className={inputWrapper}>
                <div className={phoneInput}>
                  <PhoneInput
                    country={"us"}
                    enableSearch={true}
                    preferredCountries={["us", "za", "ca", "au", "gb"]}
                    disableSearchIcon={true}
                    inputClass={inputClass}
                    buttonClass={buttonClass}
                    placeholder={t(
                      translationPrefixWithDot +
                        "forms.get-in-touch.placeholders.phone"
                    )}
                    onChange={onPhoneNumberChange}
                    value={formState.PHONE}
                    inputProps={{ required: true }}
                    countryCodeEditable={false}
                    masks={{ za: ".. ... ...." }}
                  />
                  <span className="absolute left-[82px] top-[10px] text-[#4b5563e6] pointer-events-none">
                    {formState.PHONE?.replace(
                      formState.CUSTOMFIELDS[2].FIELD_VALUE,
                      ""
                    ) === "" &&
                      t(
                        translationPrefixWithDot +
                          "forms.get-in-touch.placeholders.phone"
                      )}
                  </span>
                </div>

                {/* When invalid phone number is detected, show invalid */}
                {formError.error && formError.field === "PHONE" && (
                  <p className={errorMessage}>
                    {t(
                      translationPrefixWithDot +
                        "forms.error-messages.phone.invalid"
                    )}
                  </p>
                )}
              </div>
            </div>
            {productOfInterest && (
              <div className={inputContainer}>
                <ProductOfInterest onSelectHandler={onProductSelect} />
              </div>
            )}
            {message && (
              <div className={textareaContainer}>
                <textarea
                  name="LEAD_DESCRIPTION"
                  className={textarea}
                  placeholder={t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.placeholders.message"
                  )}
                  onInput={(e) => {
                    onChangeHandler(e);
                  }}
                  maxLength={250}
                  value={formState.LEAD_DESCRIPTION}
                />
                {formError.error && formError.field === "LEAD_DESCRIPTION" && (
                  <p className={errorMessage}>
                    {t(
                      translationPrefixWithDot +
                        "forms.error-messages.description.invalid"
                    )}
                  </p>
                )}
              </div>
            )}
            {/* Check if the form request was successful and display the correct component */}
            {thankYouState.success && thankYouState.message.length > 0 ? (
              <>
                <p className={successMessage}>{thankYouState.message}</p>
                {leadID && (
                  <img
                    src={`https://www.shareasale.com/sale.cfm?tracking=${leadID}&amount=0.00&merchantID=142065&transtype=lead`}
                    width="1"
                    height="1"
                  />
                )}
              </>
            ) : (
              <button
                className={submitButton(themeColor?.backgroundColor ?? "")}
                type="submit"
              >
                {formIsLoading ? (
                  <FontAwesomeIcon
                    icon={faSpinner as IconProp}
                    spin={true}
                    className={wantMoreInfoStyles.spinner}
                  />
                ) : (
                  t(translationPrefixWithDot + "forms.get-in-touch.button")
                )}
              </button>
            )}
            {/* ReCAPTCHA errors goes here */}
            {reCAPTCHAError !== "" && (
              <p className={errorMessage}>{reCAPTCHAError}</p>
            )}

            {/* Request errors goes here */}
            {formError.error && formError.field === "REQUEST_ERROR" && (
              <p className={errorMessage}>
                {t(
                  translationPrefixWithDot +
                    "forms.error-messages.request.invalid"
                )}
              </p>
            )}
          </form>
          {formFooter}
        </div>
        <div className={or}>OR</div>
        {bookADemoPanel || (
          <>
            <div className={demoDiv}>
              <div
                className={
                  "border-2 border-[#9299a0] p-[1.5rem] rounded-lg text-center"
                }
              >
                <h3 className={heading(themeColor?.color ?? "")}>
                  {t(
                    translationPrefixWithDot +
                      "forms.get-in-touch.book-demo.heading"
                  )}
                </h3>
                <p className={subheading}>
                  <strong>
                    {t(
                      translationPrefixWithDot +
                        "forms.get-in-touch.book-demo.subheading"
                    )}
                  </strong>
                </p>
                <p className={classNames(subheading, "!-mt-5")}>
                  <Trans components={{ br: <br />, b: <b></b> }}>
                    {translationPrefixWithDot}forms.get-in-touch.book-demo.body
                  </Trans>
                </p>
                <button
                  type={"button"}
                  className={classNames(
                    bookButtonClassNames,
                    FormButton(themeColor?.backgroundColor ?? ""),
                    "GA4-BookADemo"
                  )}
                  onClick={() => {
                    setPopupState({ ...popupState, open: true });
                  }}
                >
                  {bookButtonText ||
                    t(
                      translationPrefixWithDot +
                        "forms.get-in-touch.book-demo.button"
                    )}
                </button>
              </div>
              <p className={description}>
                <Trans
                  components={{
                    a: (
                      <a
                        className={link(themeColor?.color)}
                        href="mailto:sales@hearxgroup.com?subject=hearX Group Enquiry"
                      />
                    ),
                  }}
                >
                  {translationPrefixWithDot}forms.get-in-touch.description
                </Trans>
              </p>
              <div className={description}>
                <PrivacyText themeColor={themeColor} fontSize="text-xs" />
              </div>
            </div>
          </>
        )}
      </div>
      <PopupModal
        url={calendlyUrl}
        open={popupState.open}
        onModalClose={() => {
          setPopupState({ ...popupState, open: false });
        }}
        rootElement={popupState.element as HTMLElement}
      />
    </>
  );
};

export default RequestDemoForm;

const extractHexValue = (color: string | undefined) => {
  const REGEX_NOT_HEX_VALUES = /[^0-9a-f]/gi;
  if (
    color &&
    color?.split("#")[1] &&
    color?.split("#")[1].replace(REGEX_NOT_HEX_VALUES, "").length === 6
  ) {
    return color?.split("#")[1].replace(REGEX_NOT_HEX_VALUES, "");
  }
  return "80deea";
};

export const removeUndefinedFromArray = (array: any[]) => {
  return array.filter((item) => item !== undefined);
};
