export const wrapper = [
    "mobile:w-16",
    "mobile:h-full",
    "mobile:bg-secondary",
    "mobile:text-white",
    // Tablet & higher
    "tablet:hidden",
    // All
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "z-20",
];

export const lines = [
    // Mobile only
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "mb-1",
];

export const closed = [
    // Mobile only
    "relative",
    "w-7",
    "h-0.5",
    "bg-white",
    "mt-1",
    "mb-0.5",
    "rounded-full",
];

export const topLine = [
    // Mobile only
    ...closed,
    "transform",
    "rotate-45",
    "top-1",
];
export const bottomLine = [
    // Mobile only
    ...closed,
    "transform",
    "-rotate-45",
    "bottom-1",
];

export const text = [
    // Mobile Only
    "text-xs",
    "uppercase",
    "font-extralight",
];
