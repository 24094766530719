// Tailwind classes
export const wrapper = [
    // Mobile
    "mobile:flex-col",
    // Tablet & higher
    "tablet:flex-row",
    // All
    "flex",
    "items-start",
    "justify-center",
    "text-white",
    "bg-secondary",
    "pt-12",
    "pb-12",
    "z-10",
    "relative"
];

export const content = [
    // Mobile
    "mobile:w-full",
    "mobile:pt-10",
    // Tablet
    "tablet:w-3/5",
    "tablet:mt-16",
    "tablet:pt-0",
    // Laptop & higher
    "laptop:w-3/5",
    // All
    "flex",
    "flex-col",
    "items-center",
    "justify-start",
];

export const contentBlocks = [
    // Mobile
    "mobile:flex-col",
    "mobile:items-center",
    // Tablet
    "tablet:flex-row",
    "tablet:items-start",
    // All
    "flex",
    "justify-evenly",
    "w-full",
];
