import classNames from "classnames";
import scss from "@/styles/components/forms.module.scss";

export const form = classNames(
  "max-w-screen-xs",
  "m-auto",
  "text-center",
  "max-w-[500px]"
);
export const inputContainer = classNames(
  // Mobile
  "mobile:grid-cols-1",
  // Tablet
  "tablet:grid-cols-2",
  // All
  "gap-x-4",
  "grid"
);

export const inputWrapper = classNames(
  "relative",
  "w-full",
  "max-w-[400px]",
  "self-center",
  "justify-self-center",
  "z-40"
);
export const inputs = classNames(
  "w-full",
  "pt-2",
  "pb-2",
  "pl-4",
  "pr-4",
  "mt-2",
  "mb-2",
  "ml-auto",
  "mr-auto",
  "rounded-lg",
  "self-center",
  "justify-self-center",
  "text-base",
  "placeholder-gray-600",
  "text-secondary"
);
export const errorMessage = classNames(
  "relative",
  "-bottom-2",
  "left-0",
  "text-red-400",
  "text-xs"
);
export const phoneInput = classNames(
  "relative",
  "w-full",
  "mt-2",
  "mb-2",
  "ml-auto",
  "mr-auto",
  "rounded-lg",
  "self-center",
  "justify-self-center",
  "text-left",
  "text-secondary",
  "z-40"
);
export const submitButton = (theme?: string) =>
  classNames(
    // Mobile
    "mobile:w-full",
    "mobile:h-10",
    "mobile:text-base",
    "mobile:font-semibold",
    "mobile:mt-2",
    // Tablet
    "tablet:w-64",
    "tablet:h-12",
    "tablet:text-xl",
    "tablet:font-normal",
    "tablet:mt-5",
    // All
    "bg-primary",
    "text-white",
    "rounded-lg",
    "z-40",
    theme ?? ""
  );

export const textareaContainer = classNames(
  "w-full",
  "h-28",
  "mt-4",
  "mb-4",
  "relative"
);
export const textarea = classNames(
  // Mobile
  "mobile:w-full",
  // Tablet
  "tablet:w-full",
  // All
  "h-full",
  "resize-none",
  "rounded-lg",
  "placeholder-gray-600",
  "pt-2",
  "pb-2",
  "pl-4",
  "pr-4",
  "text-secondary"
);

// Phone input classes
export const inputClass = classNames(scss["phone-input"]);
export const buttonClass = classNames(scss["phone-button"]);
export const successMessage = classNames("text-center", "text-base", "pt-2");
