import { Trans } from "react-i18next";
// Tailwind Classes
import { link, text } from "./styles/privacy-text-classes";

const PrivacyText: React.FC<{
  fontSize?: string;
  themeColor?: { color?: string; backgroundColor?: string };
}> = ({ fontSize, themeColor }) => {
  const textClasses = [...text];

  if (textClasses.indexOf("text-sm") > -1) {
    textClasses.splice(textClasses.indexOf("text-sm"), 1);
    fontSize && textClasses.push(fontSize);
  }

  return (
    <p className={textClasses.join(" ")}>
      <Trans
        components={{
          a: (
            <a
              className={link(themeColor?.color ?? "").join(" ")}
              href="/terms"
            />
          ),
          sup: <sup />,
        }}
      >
        global.privacy-message
      </Trans>
    </p>
  );
};

export default PrivacyText;
